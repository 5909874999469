import axios from 'axios';

// Set the base URL for all requests
const API_BASE_URL = process.env.REACT_APP_BASE_URL;

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to get questions by section
export const getQuestionsBySection = async (section) => {
  try {
    const response = await api.get(`/questions/${section}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

// Function to get all sections
export const getAllSections = async () => {
    try {
      const response = await api.get(`/sections`);
      return response.data;
    } catch (error) {
      console.error('Error fetching questions:', error);
      throw error;
    }
  };

  // Function to get all sections
export const getAllQuestions = async () => {
    try {
      const response = await api.get(`/questions`);
      return response.data;
    } catch (error) {
      console.error('Error fetching questions:', error);
      throw error;
    }
  };

  // Function to delete a question by ID or index
  export const deleteQuestion = async (id, index) => {
    try {
       await api.delete('/questions', { data: { id, index } });
    } catch (error) {
      console.error('Error deleting question:', error);
      throw error;
    }
  };

  // Function to add a new question
export const addQuestion = async (formData) => {
    try {
      const response = await api.post('/questions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding question:', error);
      throw error;
    }
  };
// Function to update an existing question
export const updateQuestion = async (formData) => {
  try {
    const response = await api.put('/questions', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};
