import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { CssBaseline, Container, Grid, Box, Typography } from '@mui/material';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import QuestionsPage from './pages/QuestionsPage';
import AddQuestionForm from './pages/admin/AddQuestionForm';
import AdminPage from './pages/admin/AdminPage';
import './App.css';
import { getAllSections } from './services/apiService';

function App() {
  const [sectionName, setSectionName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSectionChange = (section) => {
    setSectionName(section);
  };

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await getAllSections();
        const validSections = response.filter(section => section != null);
        if (validSections.length > 0 && location.pathname === '/') {
          setSectionName(validSections[0]?.section);
          navigate(`/questions/${validSections[0]?.section}`);
        }
      } catch (error) {
        console.error('Failed to fetch sections', error);
      }
    };

    fetchSections();
  }, [location.pathname, navigate]);

  return (
    <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <CssBaseline />
      
      {/* Main Content with Sidebar */}
      <Grid container className="main-content" style={{ flexGrow: 1, overflow: 'auto', minHeight: '0' }}>
      
        {/* Sidebar */}
        <Grid
          item
          xs={12} // Sidebar is hidden on mobile view, using full width for main content
          sm={12} // Sidebar visible on larger devices
          md={3} // Ensure sidebar takes 3/12 of the screen in medium screens (960px - 1280px)
          lg={2} // Sidebar takes 2/12 of the screen in larger screens (>1280px)
          sx={{
            '@media (min-width:1280px)': { maxWidth: '15%' },
            '@media (min-width:960px)': { maxWidth: '20%' }, // Adjust for medium screens
            display: { xs: 'none', sm:'none', md:'block', lg:'block' }, // Hide sidebar on mobile (xs)
          }}
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            display:'block'
          }}
        >
          <Sidebar onSectionChange={handleSectionChange} />
        </Grid>

        {/* Content and Header */}
        <Grid
          item
          xs={12} // Full width for content on mobile
          sm={12} // Remaining space for content on larger devices
          md={9} // Content takes 9/12 of the screen on medium devices
          lg={10} // Content takes 10/12 of the screen on larger devices
          sx={{
            '@media (min-width:1280px)': { maxWidth: '85%' },
            '@media (min-width:960px)': { maxWidth: '80%' }, // Adjust content width for medium screens
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
            minHeight: '0', // Allows the content to shrink
          }}
        >
          {/* Header */}
          <Header sectionName={sectionName} />
          
          {/* Main Content Container */}
          <Container maxWidth="xl" style={{ padding: '0', flexGrow: 1, marginBottom:'16px' }}>
            <Routes>
              <Route path="/questions/:section" element={<QuestionsPage onSectionChange={handleSectionChange} />} />
              <Route path="/admin/add-question" element={<AddQuestionForm />} />
              <Route path="/admin/manage-questions" element={<AdminPage />} />
            </Routes>
          </Container>
        </Grid>
      </Grid>
      
      {/* Footer */}
      <Box 
        sx={{ 
          textAlign: 'center', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '60px', 
          borderTop: '1px solid #e6e6e6', 
          backgroundColor: '#f4f4f4', 
          width: '100%',
          marginTop: 'auto', // Ensures it stays at the bottom when content is small
        }}
      >
        <Typography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} ReactPrep. All rights reserved.
        </Typography>
      </Box>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
