import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Accordion, AccordionSummary, AccordionDetails, MenuItem, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addQuestion, deleteQuestion, updateQuestion, getAllSections, getQuestionsBySection } from '../../services/apiService';
import AddQuestionForm from './AddQuestionForm';
import CodeBlock from '../../components/CodeBlock';

const AdminPage = () => {
  const [questions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [isAddingNewSection, setIsAddingNewSection] = useState(false);

  // Fetch sections on component mount
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await getAllSections();
        const filteredData = response
          .filter(obj => obj.section != null)
          .sort((a, b) => a.position - b.position); // Sort by position
        setSections(filteredData);
      } catch (err) {
        console.error('Error fetching sections:', err);
      }
    };
    fetchSections();
  }, []);

  // Fetch questions when section is selected
  useEffect(() => {
    if (selectedSection && !isAddingNewSection && !questionToEdit) {
      const fetchQuestions = async () => {
        try {
          const response = await getQuestionsBySection(selectedSection);
          setQuestions(response);
        } catch (err) {
          console.error('Error fetching questions:', err);
        }
      };
      fetchQuestions();
    } else {
      setQuestions([]);
    }
  }, [selectedSection, isAddingNewSection, questionToEdit]);

  const handleDelete = async (id) => {
    try {
      await deleteQuestion(id);
      setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== id)); // Update state
    } catch (err) {
      console.error('Error deleting question:', err);
    }
  };

  const handleEdit = (question) => {
    setSelectedSection(selectedSection); 
    setQuestionToEdit(question); 
    setIsAddingNewSection(false); 
  };

  const handleSave = async (formData, method) => {
    try {
      if (method === 'PUT') {
        // Update existing question
        await updateQuestion(formData);
        setQuestions(prevQuestions => prevQuestions.map(q => q.id === formData.get('id') ? { ...q, ...Object.fromEntries(formData.entries()) } : q));
      } else {
        // Add new question
        const newQuestion = Object.fromEntries(formData.entries());
        await addQuestion(formData);
        setQuestions(prevQuestions => [...prevQuestions, newQuestion]); // Update state with new question
      }

      // Reset form and hide it after successful save
      setIsAddingNewSection(false);
      setQuestionToEdit(null);
    } catch (err) {
      console.error('Error saving question:', err);
    }
  };

  const handleCancelEdit = () => {
    setQuestionToEdit(null);
    setIsAddingNewSection(false);
  };

  const handleSectionChange = (event) => {
    if (event.target.value === 'new') {
      setIsAddingNewSection(true);
      setSelectedSection('');
      setQuestionToEdit(null);
    } else {
      setSelectedSection(event.target.value);
      setIsAddingNewSection(false);
      setQuestionToEdit(null);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderAnswerContent = (answer) => {
    if (typeof answer === 'string') {
      try {
        answer = JSON.parse(answer);
      } catch (error) {
        console.error('Failed to parse answer:', error);
        return <Typography>Error parsing answer</Typography>;
      }
    }

    return answer.map((content, index) => {
      if (typeof content === 'string' || content?.type === 'text') {
        return (
          <Typography
            key={index}
            style={{ fontFamily: 'Open Sans, sans-serif', marginBottom: '10px' }}
            dangerouslySetInnerHTML={{
              __html: typeof content === 'string' ? content : content.content,
            }}
          />
        );
      } else if (content.type === 'code') {
        return <CodeBlock key={index} code={content.content} language="jsx" filename="example" />;
      }
      return null;
    });
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1">
          Admin Page - Manage Questions
        </Typography>
        {!isAddingNewSection && !questionToEdit && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setIsAddingNewSection(true)}
          >
            Add New Question
          </Button>
        )}
      </Box>
      
      <TextField
        label="Select Section"
        value={selectedSection}
        onChange={handleSectionChange}
        select
        fullWidth
        margin="normal"
        disabled={questionToEdit !== null}
      >
        {sections.map((section) => (
          <MenuItem key={section.section} value={section.section}>
            {section.section}
          </MenuItem>
        ))}
      </TextField>

      {(isAddingNewSection || questionToEdit) && (
        <AddQuestionForm 
          questionToEdit={questionToEdit} 
          onSave={handleSave}
          onCancel={handleCancelEdit}
          sectionName={selectedSection} 
          sections={sections} 
        />
      )}

      {!isAddingNewSection && !questionToEdit && questions.length > 0 && questions.map((q, index) => (
        <Accordion 
          key={q.id} 
          expanded={expanded === q.id} 
          onChange={handleChange(q.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bold', marginRight: '10px', color: 'blue' }}>
              {q.sectionName}:
            </Typography>
            <Typography style={{ flexGrow: 1, color: 'blue' }}>
              {q.question}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEdit(q)}
              style={{ marginLeft: '10px' }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(q.id)}
              style={{ marginLeft: '10px' }}
            >
              Delete
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {renderAnswerContent(q.answer)}
              {q.image && <img src={q.image} alt="Related to the question" style={{ marginTop: '10px', maxWidth: '100%' }} />}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default AdminPage;
