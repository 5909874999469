import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';

const Header = ({ sectionName }) => {
  return (
    <AppBar position="static" sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: {
        xs: 'end', // Align items to the end on small screens
        md: 'center', // Center align items on medium and larger screens
      },
    }} style={{ backgroundColor: '#ffffff', color: '#333333', boxShadow: 'none', borderBottom: '1px solid #e6e6e6'}}>
      <Toolbar>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 11 }}>
          <Typography variant="h6" component="div" sx={{ fontFamily: 'Lora, serif', textAlign: 'center' }}>
            {sectionName ? `${sectionName?.toUpperCase()} Interview Questions` : ''}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
