import React from 'react';
import { Box, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ code, language, filename }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    alert('Code copied to clipboard!');
  };

  return (
    <Box sx={{ position: 'relative', mb: 2 }}>
      {filename && (
        <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#4d4d4d',
            color: '#fff',
            padding: '5px 10px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginBottom: '-10px',  // Reduce the space between the header and code block to 0.1px
          }}
        >
          <span>{filename}</span>
          <IconButton onClick={handleCopy} size="small" sx={{ color: '#fff' }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <SyntaxHighlighter language={language} style={materialDark}>
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};

export default CodeBlock;
