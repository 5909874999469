import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import parse from 'html-react-parser';
import CodeBlock from './CodeBlock';

// Function to handle copying to clipboard
const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
};

// Modified renderAnswerContent to parse <pre> or <code> tags
const renderAnswerContent = (answer, sectionName) => {
  return answer.map((content, index) => {
    if (content.type === 'text') {
      // Parse the HTML content using html-react-parser
      const parsedContent = parse(content.content, {
        replace: (domNode) => {
          if (domNode.name === 'pre' || domNode.name === 'code') {
            const codeContent = domNode.children[0].data;
            return (
              <Box key={index} mb={2}>
                {/* Section Heading */}
                <Typography variant="h6" gutterBottom>
                  {sectionName}
                </Typography>
                {/* Code Block */}
                <Box position="relative">
                  <CodeBlock code={codeContent} language="jsx" />
                  {/* Copy Button */}
                  <Button
                    size="small"
                    startIcon={<ContentCopyIcon />}
                    style={{ position: 'absolute', top: 10, right: 10 }}
                    onClick={() => handleCopy(codeContent)}
                  >
                    Copy
                  </Button>
                </Box>
              </Box>
            );
          }
        },
      });

      return <div key={index}>{parsedContent}</div>;
    }

    return null;
  });
};

const QuestionAccordion = ({ question, answer, sectionName, expanded, onChange }) => (
  <Accordion expanded={expanded} onChange={onChange}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography style={{ fontFamily: 'Lora, serif' }}>{question}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box>{renderAnswerContent(answer, sectionName)}</Box>
    </AccordionDetails>
  </Accordion>
);

export default QuestionAccordion;
