import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TextField, Button, Container, Typography, MenuItem, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const AddQuestionForm = ({ questionToEdit, onSave, onCancel, sections, sectionName }) => {
  const [currentSectionName, setCurrentSectionName] = useState(questionToEdit ? sectionName : sectionName);
  const [newSectionName, setNewSectionName] = useState(questionToEdit ? questionToEdit.sectionName : '');
  const [isNewSection, setIsNewSection] = useState(false);
  const [question, setQuestion] = useState(questionToEdit ? questionToEdit.question : '');
  const [answers, setAnswers] = useState(questionToEdit && questionToEdit.answer ? questionToEdit.answer : [{ type: 'text', content: '' }]);
  const [image, setImage] = useState(questionToEdit ? questionToEdit.image : null);
  const [index, setIndex] = useState(questionToEdit ? questionToEdit.index : '');

  useEffect(() => {
    if (questionToEdit) {
      setCurrentSectionName(sectionName);
      setQuestion(questionToEdit.question);
      setAnswers(questionToEdit.answer || [{ type: 'text', content: '' }]);
      setImage(questionToEdit.image);
      setIndex(questionToEdit.index);
    } else {
      setCurrentSectionName(sectionName);
    }
  }, [questionToEdit, sectionName]);

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].content = value; // Update content
    setAnswers(updatedAnswers);
  };

  const addAnswerField = () => {
    setAnswers([...answers, { type: 'text', content: '' }]);
  };

  const removeAnswerField = (index) => {
    const updatedAnswers = answers.filter((_, i) => i !== index);
    setAnswers(updatedAnswers);
  };

  const handleSectionChange = (e) => {
    const value = e.target.value;
    if (value === 'new') {
      setIsNewSection(true);
      setCurrentSectionName('');
    } else {
      setIsNewSection(false);
      setCurrentSectionName(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('id', questionToEdit ? questionToEdit.id : uuidv4());
    formData.append('sectionName', isNewSection ? newSectionName : currentSectionName);
    formData.append('question', question);
    formData.append('answer', JSON.stringify(answers));
    if (image) {
      formData.append('image', image);
    }
    if (index) {
      formData.append('index', parseInt(index, 10));
    }

    // Call the appropriate API (PUT for update, POST for new entry)
    if (questionToEdit) {
      await onSave(formData, 'PUT');
    } else {
      await onSave(formData, 'POST');
    }
  };

  const renderAnswerContentWithSyntaxHighlighting = (content) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(content, 'text/html');
    const blocks = Array.from(parsedDocument.body.childNodes);

    return blocks.map((block, index) => {
      if (block.nodeName === 'PRE' && block.firstChild && block.firstChild.nodeName === 'CODE') {
        const language = block.firstChild.className.replace('language-', '') || 'javascript';
        return (
          <SyntaxHighlighter language={language} style={vscDarkPlus} key={index}>
            {block.firstChild.textContent}
          </SyntaxHighlighter>
        );
      } else {
        return <div key={index} dangerouslySetInnerHTML={{ __html: block.outerHTML }} />;
      }
    });
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        {(questionToEdit) ? 'Edit Question' : 'Add Question'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Select or Add Section"
          value={isNewSection ? newSectionName : currentSectionName}
          onChange={handleSectionChange}
          select={!isNewSection}
          fullWidth
          margin="normal"
          required
        >
          {(sections || []).map((section) => (
            <MenuItem key={section.section} value={section.section}>
              {section.section}
            </MenuItem>
          ))}
          {!questionToEdit && <MenuItem value="new">Add New Section</MenuItem>}
        </TextField>

        {isNewSection && (
          <TextField
            label="New Section Name"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        )}

        <TextField
          label="Question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          fullWidth
          margin="normal"
          required
        />

        {(answers || []).map((answer, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <ReactQuill
                value={answer.content}
                onChange={(value) => handleAnswerChange(index, value)}
                modules={{
                  toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link', 'image'],
                    [{ 'align': [] }],
                    ['code-block'],
                    ['clean']
                  ]
                }}
                formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image', 'align', 'code-block']}
                placeholder="Write your answer or code block here..."
              />
              <style jsx>{`
        .ql-editor p {
          margin-top: 0;
          margin-bottom: 0;
        }
      `}</style>
            </Box>
            <IconButton onClick={() => removeAnswerField(index)} sx={{ ml: 2 }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Box>
          <Button type="button" onClick={addAnswerField} variant="outlined" sx={{ mr: 2 }}>
            Add Answer Part
          </Button>

          <Button type="submit" variant="contained" color="primary">
            {questionToEdit ? 'Save Changes' : 'Add Question'}
          </Button>

          {questionToEdit && (
            <Button type="button" variant="contained" color="secondary" onClick={onCancel} sx={{ ml: 2 }}>
              Cancel
            </Button>
          )}
        </Box>
        <div>
          <h3>Rendered Content with Syntax Highlighting:</h3>
          <div>
            {answers.map((answer, idx) => renderAnswerContentWithSyntaxHighlighting(answer.content))}
          </div>
        </div>
      </form>
    </Container>
  );
};

export default AddQuestionForm;
