import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Drawer, IconButton, Box } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { getAllSections } from '../services/apiService';
import logo from '../assets/logo.png'; // Import the logo

const Sidebar = ({ onSectionChange }) => {
  const [sections, setSections] = useState([]);
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const data = await getAllSections();
        const filteredData = data
          .filter(obj => obj.section != null)
          .sort((a, b) => a.position - b.position); // Sort by position
        setSections(filteredData);
      } catch (err) {
        console.error('Error fetching all sections:', err);
      }
    };
    fetchSections();
  }, []);

  useEffect(() => {
    const currentSection = location.pathname.split('/').pop();
    if (currentSection) {
      onSectionChange(currentSection);
    }
  }, [location, onSectionChange]);

  // Toggle drawer state
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      {/* Add the logo at the top */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1px' }}>
        <img src={logo} alt="ReactPrep Logo" style={{ height: '90px', width: '200px' }} />
      </Box>

      <List
        component="nav"
        sx={{ pt: 0 }}
        style={{
          borderRight: '1px solid #e6e6e6',
          backgroundColor: '#f4f4f4',
          height: '100vh',
          overflowY: 'auto',
          zIndex: 0,
        }}
      >
        {sections.map((section) => (
          <ListItem
            button
            component={NavLink}
            to={`/questions/${section.section}`}
            key={section.section}
            onClick={() => {
              onSectionChange(section.section);
              setMobileOpen(false); // Close drawer on selection in mobile view
            }}
            style={({ isActive }) => ({
              backgroundColor: isActive ? '#e0e0e0' : '',
              borderLeft: isActive ? '6px solid #545454' : '',
              textDecoration: 'none',
            })}
            exact
          >
            <ListItemText primary={section.section.toUpperCase()} />
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <>
      {/* Menu icon for mobile */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' }, // Show only on small and extra-small screens
          paddingLeft: 4,
          position: 'fixed',
          top: '16px',
          left: '16px',
          zIndex: 20000,
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer for mobile view */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          display: { xs: 'block', md: 'none' }, // Display drawer only on small screens
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>

      {/* Permanent drawer for desktop view */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' }, // Hide on mobile, show on desktop
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            height: '100vh', // Ensure the drawer takes up the remaining height
            paddingTop: '0px',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;
