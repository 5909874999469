import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getQuestionsBySection } from '../services/apiService';
import QuestionAccordion from '../components/QuestionAccordion';

const QuestionsPage = ({ onSectionChange }) => {
  const { section } = useParams();
  const [questions, setQuestions] = useState([]);
  const [expanded, setExpanded] = useState(0);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const data = await getQuestionsBySection(section);
        setQuestions(data);
        setExpanded(0); // Open the first accordion by default when questions are fetched
        onSectionChange(section); // Update the section name in the header
      } catch (err) {
        console.error('Error fetching questions:', err);
      }
    };
    fetchQuestions();
  }, [section, onSectionChange]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {questions.map((q, index) => (q?.question && q.answer && <QuestionAccordion
          key={index} 
          question={q?.question} 
          answer={q?.answer} 
          expanded={expanded === index}
          onChange={handleChange(index)}
        />
      ))}
    </>
  );
};

export default QuestionsPage;
